enum TextureKeys {
    Background = 'background',
    RocketMouse = 'rocket-mouse',
    MouseHole = 'mouse-hole',
    Window1 = 'window-1',
    Window2 = 'window-2',
    Bookcase1 = 'bookcase-1',
    Bookcase2 = 'bookcase-2'
}

export default TextureKeys;
